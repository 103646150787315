<template>
	<div style="padding: 20px;">



		<HomeOther></HomeOther>

		<!-- <div class="banner-list" style="width: 500px;">
			<el-carousel :interval="5000">
				<el-carousel-item>
					<el-image style="width: 100%; height: 100%"
						:src="'https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/homeBanner/1.jpg'"
						:preview-src-list="['https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/homeBanner/1.jpg']"></el-image>
				</el-carousel-item>
				<el-carousel-item>
					<el-image style="width: 100%; height: 100%"
						:src="'https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/homeBanner/2.jpg'"
						:preview-src-list="['https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/homeBanner/2.jpg']"></el-image>
				</el-carousel-item>
				<el-carousel-item>
					<el-image style="width: 100%; height: 100%"
						:src="'https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/homeBanner/3.jpg'"
						:preview-src-list="['https://huyi-oss-wh.oss-cn-hangzhou.aliyuncs.com/homeBanner/3.jpg']"></el-image>
				</el-carousel-item>
			</el-carousel>
		</div> -->

		<!-- <div>
			<el-calendar v-model="value">
				<template slot="dateCell" slot-scope="{date, data}">
					<p :class="data.isSelected ? 'is-selected' : ''">
						{{ data.day.split('-').slice(1).join('-') }}
					</p>
				</template>
			</el-calendar>
		</div> -->

	</div>

</template>

<script>
	import HomeOther from './HomeOther.vue';
	export default {
		components: {
			HomeOther
		},
		data() {
			return {
				// value: new Date()
			}
		}
	}
</script>

<style scoped>
	.is-selected {
		color: #1989FA;
	}
</style>